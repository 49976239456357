import {
  Alert,
  ButtonMore,
  colors,
  ffdinPro,
  fontFamily,
  fontSize,
  HtmlContent,
  IconRoundedMinus30,
  IconRoundedPlus30,
  Loader,
  mediaQuery,
  PageTitle,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import { NetworkStatus } from "apollo-client";
import React, { useState } from "react";
import posed from "react-pose";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import { useFaqs } from "../lib/hooks/useFaqs";
import { withPage } from "../lib/withPage";

const FaqsContainer = styled.div`
  &:not(:first-child) {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid ${colors.lightLt};

    ${mediaQuery("phablet")`
      padding-top: 20px;
      margin-top: 20px;
    `}
  }
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  ${fontSize("25px")}
  letter-spacing: -0.5px;
  color: ${colors.darkDk};
  flex: 1;
  display: flex;
  align-items: center;
  transition: color 0.2s;

  ${mediaQuery("phablet")`
    ${fontSize("19px")}
  `}
`;

const Header = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  svg {
    transition: fill 0.2s;
  }

  &:hover {
    ${Title} {
      color: ${colors.primary};
    }

    svg {
      fill: ${colors.primary};
    }
  }
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;

  ${mediaQuery("phablet")`
    margin-left: 15px;
  `}
`;

const Container = styled.div`
  align-items: flex-start;
  border-top: 1px dotted ${colors.light};

  ${mediaQuery("desktop")`
    padding-top: 40px;
    margin-top: 40px;
    display: flex;
  `}

  ${mediaQuery("phablet")`
    padding-top: 20px;
    margin-top: 20px;
  `}
`;

const Text = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

const Highlight = styled.span`
  ${fontFamily(ffdinPro.condensedBold)}
`;

const Icon = posed.div({
  off: {
    opacity: 0,
  },
  on: {
    opacity: 1,
  },
});

const ButtonMoreContainer = styled.div`
  margin-top: 40px;
`;

const Faqs = () => {
  const [isOpen, setisOpen] = useState<{ [key: string]: boolean }>({});
  const { faqs, loading, onLoadMore, networkStatus, pageInfo } = useFaqs({
    limit: 30,
    page: 0,
  });

  return (
    <Layout>
      <Wrapper>
        <Row center="xs">
          <Col xs={12} sm={12} md={12} lg={8}>
            <PageTitle>Foire aux questions</PageTitle>
            {loading && <Loader />}
            {!loading && faqs.length === 0 ? (
              <Alert type="warning">Aucun element.</Alert>
            ) : (
              faqs.map((faq, i) => (
                <FaqsContainer key={i}>
                  <div
                    onClick={() => setisOpen({ ...isOpen, [i]: !isOpen[i] })}
                  >
                    <Header>
                      <div>
                        <Icon
                          pose={isOpen[i] ? "on" : "off"}
                          style={{
                            width: 30,
                            height: 30,
                            display: "inline-block",
                          }}
                        >
                          <IconRoundedMinus30
                            style={{ display: "block" }}
                            fill={colors.darkDk}
                            width={30}
                            height={30}
                          />
                        </Icon>
                        <Icon
                          pose={isOpen[i] ? "off" : "on"}
                          style={{
                            display: "inline-block",
                            height: 30,
                            left: 0,
                            position: "absolute",
                            top: 0,
                            width: 30,
                          }}
                        >
                          <IconRoundedPlus30
                            style={{ display: "block" }}
                            width={30}
                            height={30}
                            fill={colors.darkDk}
                          />
                        </Icon>
                      </div>
                      <TitleContainer>
                        <Title>
                          <Highlight>{faq.title}</Highlight>
                        </Title>
                      </TitleContainer>
                    </Header>
                  </div>
                  <Text pose={isOpen[i] ? "on" : "off"}>
                    <Container>
                      <HtmlContent html={faq.text} />
                    </Container>
                  </Text>
                </FaqsContainer>
              ))
            )}
            {pageInfo.hasNextPage && (
              <ButtonMoreContainer>
                <ButtonMore
                  onClick={onLoadMore}
                  disabled={networkStatus === NetworkStatus.fetchMore}
                />
              </ButtonMoreContainer>
            )}
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

export default withPage(Faqs, {
  title: "Faqs",
});
